import React from "react";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";

function KontaktPage({ data }) {
  return (
    <Layout>
      <SEO keywords={[`i-em `, `bayreuth`, `kontakt`, `p&e`]} title="Kontakt" />
      <section className="container mx-auto mt-12">
        <div className="grid md:grid-cols-3">
          <div></div>
          <div>
            <h1 className="font-header text-3xl">Kontakt</h1>
            <p>Nehmen Sie gerne Kontakt mit uns auf.</p>
            <br /> <br />
            <p className="font-header text-2xl">Sekretariat</p>
            <br />
            {console.log(data)}
            <Img
              style={{ width: 200, height: 200 }}
              className="rounded-full w-200 h-200 mb-4"
              fixed={data?.Ficht?.Profilbild?.localFile?.childImageSharp?.fixed}
            />
            <p>
              Claudia Ficht <br />
              +49 (0) 921 554148 <br />
              Universität Bayreuth <br />
              Institut für Philosophie <br />
              Universitätsstr. 30 <br />
              95447 Bayreuth <br />
              claudia.ficht@uni-bayreuth.de
              <br />
              <br />
              <br />
              <p className="font-header text-2xl">Sprechstunde</p>
              <br />
              <Img
                style={{ width: 200, height: 200 }}
                className="rounded-full w-200 h-200 mb-4"
                fixed={
                  data?.Brink?.Profilbild?.localFile?.childImageSharp?.fixed
                }
              />
              <p>
                Prof. Dr. Dr. Alexander Brink <br />
                immer montags, 10-12 Uhr. <br /> Gebäude: GWII | Zi. 01.21{" "}
                <br />
                alexander.brink@uni-bayreuth.de
              </p>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default KontaktPage;

export const KontaktQuery = graphql`
  query {
    Ficht: strapiPeople(Nachname: { eq: "Ficht" }) {
      id
      Profilbild {
        localFile {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    Brink: strapiPeople(Nachname: { eq: "Brink" }) {
      Profilbild {
        localFile {
          childImageSharp {
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
